import React,{useState} from 'react';
import Buble from './Bubble';
import './Message.css'


function Messages (props){
    //console.log(props)
  
    let sug ;
   const[state,setState]=useState([{
      value : ""
   }])
  
   
   const handleChange =(e)=> {
    const msg_value =e.target.value;
    setState({...state,value: msg_value});
  }
 
 

 const send=()=>{
    
    
      console.log(state.value);
      console.log(props.gid);
   
    var json = {
        data: state.value,
        companionUid:props.gid,
        currentUid : props.useref
      }

      var options = {
        method:'POST', 
        headers:{
            'Content-Type':'application/json'
        },
        body:JSON.stringify(json)
    };
          //call back from function
     fetch('/send',options).then(response=>{
         console.log(response.body)
     })
         
   
//      for (var i = 0; i< props.mess.length; i++){
//         sug = props.mess[i] ; 
//          console.log(sug);       
// } 
  }
  console.log(props.mess)
 
 
 
  

        return(
            <div className="App">
                     <h1>{props.gid === "" ? "no companion" : props.gid}</h1>
                     {
                       props.mess.map((sol)=>(
                        <>
                        <Buble Messages={sol} />
                       </>
                       ))

                        
                      }

                     
                        
                         <input 
                            value={state.value}
                            name="text"
                            type="text" 
                            placeholder ="Send Message"  
                            onChange={handleChange}
                            />
                  <button onClick={send}>send Message</button>
                      
                  
                     
            </div>
         );
      
}


export default Messages;