import React from 'react';
import Home from './Home';
import Auth1 from './auth1';
//import './App.css';
import * as firebase from 'firebase';
class App extends React.Component{
   constructor(){
     super()
     this.state ={
       user:null
     }
   }

  componentDidMount(){
    this.authListener();
  }
  authListener(){
    firebase.auth().onAuthStateChanged((user)=>{
      if(user){
        this.setState({user});
      }
      else{
        this.setState({user:null});
      }
    });
  
  }
  
  render(){

    return (
      <div className="App">
       {
         this.state.user?
        (<Home/>):(<Auth1/>)
       } 
      </div>
    );

  }
 
}

export default App;
