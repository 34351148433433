import React from 'react';
import * as firebase from 'firebase';
import Messages from './Messages';
import './home.css';

class Home extends React.Component {
    constructor(){
        super()
        this.state={
            user:{},
            sugestions:[],
            companion_Uid:""
            //loading: true
        }
        this.handlechange = this.handlechange.bind(this)
        this.querry = this.querry.bind(this);
        this.answer = this.answer.bind(this);
        this.het =[];
        this.euid =[];
        this.d ="";
        this.gid = [];
        this.createData=[];
        this.test_01 =[]
    }
    handlechange(event){
      const {name,value,} = event.target
      this.setState({
        
        [name]:value
      
      })
    
    }
    componentDidMount(){
        this.authListener();
       // console.log(this.state.user)
        if (window.localStorage.getItem('userj')) {
      this.setState({companionUid : window.localStorage.getItem('userj')})
     }else{
       console.log("no companion found!")
     }

    }
    authListener(){
        firebase.auth().onAuthStateChanged((user)=>{
          if(user){
            this.setState({user});
            console.log(this.state.user.email)
          }
          else{
            this.setState({user:null});
          }
        });
      
      }

    logout=()=>{
        firebase.auth().signOut();
    }
    // chose the person you wanna talk to
    async answer(id){
      console.log("Junior Dev: " + this.euid[id]);

     
      var json = {
        currentUid: this.state.user.uid,
        companionUid: this.euid[id]
      }

      //Actually doesn't have to check..
    
    
      window.localStorage.setItem('userj',json.companionUid );
      
      this.setState({companion_Uid:this.euid[id]})
      var options = {
        method:'POST',
        headers:{
            'Content-Type':'application/json'
        },
        body:JSON.stringify(json)

    };
          //call back from function
          var response = await fetch('/create',options);
          this.createData = await response.json();
              
         console.log(this.createData.data);
         if(this.createData.data !== undefined){

         for (let index = 0; index < this.createData.data.length; index++) {
           this.test_01.push(this.createData.data[index].data);
           
         }
        }
        else
        {
          console.log("array doesn't exist")
        }


    }
    // function to look for the person you wanna talk too
    async querry(e){
      e.preventDefault();
      var data =this.state.query;
      var json = {
          data: data
       }
      var options = {
          method:'POST',
          headers:{
              'Content-Type':'application/json'
          },
          body:JSON.stringify(json)
      };
  
      //call back from function
       var response = await fetch('/searchquery',options);
       var data2 = await response.json();

      console.log(data2);

      let dat= data2.databack.map(s=>{
        //setting up the data we get back from the server
        let checksuid = !this.het.includes(s.uid) && s.uid !== this.state.user.uid;
        if(checksuid){this.euid.push(s.uid)}
          let checks = !this.het.includes(s.email) &&  s.email !== this.state.user.email;
         if(checks ){this.het.push(s.email)}
         return s.email
        });
      console.log("all" + dat);
      console.log(this.het);

        this.setState({sugestions: this.het})
 
     console.log(this.state.sugestions)
    }

    render(){
            
        return (
            <div className="App">
               <div className="head">
               <ul >
                    <li><a className="active" href="#home">Home</a></li>
                    <li><a href="#news">News</a></li>
                    <li><a href="#contact">Contact</a></li>                   
                    <li><button onClick={this.logout}>Log Out</button></li>
                    <li style={{float:"right"}}><a href="#about">About</a></li>
                </ul>
             
     
                    
                    <h1>{this.state.user.uid}</h1>
                     <input 
                        value={this.state.query}
                        name="query"
                        type="query" 
                        placeholder ="Email"  
                        onChange={this.handlechange}
                    />
                    <button name="querry" onClick={this.querry}>query</button>
            </div>


                 <div className="side">
                    {
                        this.state.sugestions.map((sug,id)=>(
                          <>
                           <button onClick={()=>this.answer(id)} key={id}> {sug}</button>
                          </>
                        ))
                      }
                 </div>

                      <div className="nun">
                       
                      <Messages mess={this.test_01} gid={this.state.companion_Uid} useref={this.state.user.uid}/>
                      </div>
                      <div className = "footer">
                        <h1>Policy of chat js</h1>
                      </div>
            </div>
          );

    }
  
}

export default Home;
