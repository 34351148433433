import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as firebase from 'firebase';

const firebaseConfig = {
    apiKey: "AIzaSyCIxavUpCGcs1MdGnlPl7sfkWPnK_wtQEQ",
    authDomain: "lafont-18649.firebaseapp.com",
    databaseURL: "https://lafont-18649.firebaseio.com",
    projectId: "lafont-18649",
    storageBucket: "lafont-18649.appspot.com",
    messagingSenderId: "360224949824",
    appId: "1:360224949824:web:413be7e10539e114"
  };
firebase.initializeApp(firebaseConfig);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
