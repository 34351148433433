import React from 'react';

import './Bubble.css'


function Bubble (props){
    //console.log(props)

  
        return(
          <div>
              <h1>{props.Messages}</h1>
          </div>
         );
      
}


export default Bubble;

