import React from 'react';
//import logo from './logo.svg';
import './auth.css';
import * as firebase from 'firebase';

class Auth1 extends React.Component {
constructor(){
  super()
  this.state={
    email:"",
    password:""
    

  }
  this.handlechange = this.handlechange.bind(this)
  this.submit = this.submit.bind(this)
  this.signin = this.signin.bind(this)
}
handlechange(event){
  const {name,value,} = event.target
  this.setState({
    
    [name]:value
  
  })

}
submit(e){
  e.preventDefault();
  const auth = firebase.auth();
  const promise =auth.createUserWithEmailAndPassword(this.state.email,this.state.password);
  promise.catch(e=>console.log(e.message));
 
}
signin(e){

    e.preventDefault();
  const auth = firebase.auth();
  const promise =auth.signInWithEmailAndPassword(this.state.email,this.state.password);
  promise.catch(e=>console.log(e.message));
 

}

 render(){

  return (
    <div className="App2">
      
      <form className="box">
      <h1>Shy Extreme</h1>
      <input 
       value={this.state.email}
       name="email"
       type="email" 
       placeholder ="Email"  
       onChange={this.handlechange}
       />
       <br/>
       <input 
       value={this.state.password}
       name="password"
       type="password" 
       placeholder ="Password"  
       onChange={this.handlechange}
       />
       <br/>
       <h2>{this.state.email}</h2>
       <button name="su" onClick={this.submit}>sign Up</button>
       <button name="si" onClick={this.signin}>Sign In</button>

      </form>
      
    </div>
  );

 }
  
}

export default Auth1;